<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-4 pb-4">
      <v-row>
        <v-col lg="12" md="12">
          <h6 class="text-h6 text-typo font-weight-bold mb-3">
             <tooltip :text="'Messages'|trans" bottom></tooltip>
            </h6>
          <div class="pa-5 border border-radius-lg d-flex align-center">
            <Scroll-Div width="100%" height="200px">
              <p v-if="messages.length === 0" class="text-sm">
                {{ 'No messages yet!' | trans }}
              </p>

              <v-list three-line>
                <template v-for="(item, index) in messages">
                  <v-list-item :key="item.id" three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-body text-sm"
                        >{{ item.createdAt }} - {{ item.createdBy.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="line-clamp: initial"
                      ><span v-html="item.message" v-linkified></span></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider :key="`${item.id}-divider`"></v-divider>
                </template>
              </v-list>
            </Scroll-Div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form ref="form">
            <v-textarea
              rows="2"
              solo
              dense
              :placeholder="'Write a message'|trans"
              auto-grow
              :hint="'You may submit your message in English or in your native language'|trans"
              v-model="helpRequestMessage.message"
              v-on:keyup.enter.exact.prevent="sendMessage"
              @keydown.enter.shift.exact.prevent="helpRequestMessage.message += '\n'"
            ></v-textarea>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              text-white
            "
            color="#5e72e4"
            small
            :loading="isSending"
            @click="sendMessage"
          >Send
          </v-btn>
          <span class="text-xs">{{ "(SHIFT + ENTER for newline)"|trans }}</span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Tooltip from "@/components/Tooltip";

export default {
  name: "HelpRequestMessages",
  components: {Tooltip},
  props: {
    helpRequestGuid: {
      type: String,
    },
  },
  data() {
    return {
      messages: [],
      helpRequestMessage: {
        message: "",
      },
      isSending: false,
      getMessagesInterval: 5000,
      interval: null,
    };
  },
  mounted() {
    this.getMessages();

    this.interval = setInterval(() => this.getMessages(), this.getMessagesInterval);
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    sendMessage() {
      this.isSending = true;

      fetch(
        process.env.VUE_APP_API_URL +
          "/help-requests/" +
          this.helpRequestGuid +
          "/messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
          body: JSON.stringify(this.helpRequestMessage),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to send the message"),
              text: data.message ? data.message : "",
            });

            return false;
          }

          this.$notify({
            group: "global",
            type: "success",
            title: this.$translator.trans("Message sent"),
            text: data.message ? data.message : "",
          });

          this.getMessages();
          this.reset();
        })
        .catch((error) => {
          console.error("Error:", error);

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to send the message"),
            text: error.message ? error.message : "",
          });
        });

      this.isSending = false;
    },
    getMessages() {

      let $this = this;

      fetch(
        process.env.VUE_APP_API_URL +
          "/help-requests/" +
          this.helpRequestGuid +
          "/messages",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to fetch messages"),
              text: data.message ? data.message : "",
            });

            $this.getMessagesInterval += 1000;

            if($this.getMessagesInterval >= 15000){
              clearInterval($this.interval);
              $this.interval = null;
            }

            return false;
          }

          $this.messages = data.messages;
          $this.getMessagesInterval = 5000;
        })
        .catch((error) => {
          console.error("Error:", error);

          $this.getMessagesInterval += 1000;

          if($this.getMessagesInterval >= 15000){
            clearInterval($this.interval);
            $this.interval = null;
          }

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to fetch messages"),
            text: error.message ? error.message : "",
          });
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
};
</script>

<style scoped></style>
