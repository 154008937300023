<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-4 pb-4">
      <v-row>
        <v-col lg="12" md="12">
          <h6 class="text-h6 text-typo font-weight-bold mb-4">{{ "Track request"|trans }}</h6>
          <v-timeline dense align-top class="timeline-line-color">
            <v-timeline-item
              v-for="(item, i) in timeline"
              :key="item.title + i"
              small
              class="timeline"
            >
              <template v-slot:icon>
                <v-avatar size="26" color="#ffffff">
                  <v-icon :class="`text-gradient text-` + item.btn" size="16"
                    >{{ item.icon }}
                  </v-icon>
                </v-avatar>
              </template>

              <v-card width="480">
                <v-card-title class="px-0 pt-0 pb-1 d-block">
                  <h6 class="text-dark text-sm font-weight-bold mb-0 d-block">
                    {{ item.title }}
                  </h6>
                  <p class="text-secondary font-weight-bold text-xs mb-0">
                    {{ item.createdAt }}
                  </p>
                </v-card-title>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "HelpRequestStatusTracker",
  props: {
    helpRequest: {
      type: Object,
    },
  },
  data() {
    return {
      timeline: [],
    };
  },
  mounted() {
    this.getStatusTrack();
  },
  methods: {
    getStatusTrack: function () {
      fetch(
        process.env.VUE_APP_API_URL +
          "/help-requests/" +
          this.helpRequest.guid +
          "/status_track",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to fetch the help request status history"),
              text: data.message ? data.message : "",
            });

            return false;
          }

          this.timeline = data.helpRequestStatusTracks.map(function (item) {
            let color = "#b0eed3";
            let iconColor = "#b0eed3";
            let icon = "ni ni-cart";
            let btn = "secondary";
            switch (item.newStatus) {
              case 0:
                icon = "ni-bell-55";
                btn = "warning";
                break;
              case 10:
                icon = "fa fa-search";
                break;
              case 20:
                icon = "fa fa-paper-plane";
                break;
              case 30:
                icon = "fa fa-spinner";
                break;
              case 40:
                icon = "fa fa-check";
                btn = "success";
                break;
              case 50:
                icon = "fa fa-times";
                btn = "danger";
                break;
            }

            return {
              title: item.translatedDescription,
              createdAt: item.createdAt,
              color: color,
              iconColor: iconColor,
              icon: icon,
              btn: btn,
              author: item.createdByName,
            };
          });

        })
        .catch((error) => {
          console.error("Error:", error);

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to fetch the help request status history"),
            text: error.message ? error.message : "",
          });
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
};
</script>
