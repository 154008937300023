<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on, attrs }">
      {{text}}
      <v-icon
          v-bind="attrs"
          v-on="on"
      >
        fa fa-language
      </v-icon>
    </template>
    <span>{{'This content is translated automatically based on your language settings'|trans}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props:{
    text: String,
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
  },

}
</script>